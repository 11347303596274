import { useMutation } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

export const usePaymentIntent = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation<{ clientSecret: string | null }>({
    mutationFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/stripe/create-payment-intent'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

type ExecutePaymentClient = (typeof apiClients)['/api/stripe/execute-payment-intent']['POST']['client']

export const useExecutePaymentIntent = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation<
    Awaited<ReturnType<ExecutePaymentClient>>['body'],
    { message: string },
    Parameters<ExecutePaymentClient>[0]['body']
  >({
    mutationFn: async (args) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/stripe/execute-payment-intent'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: args
      })

      if (!res.ok) {
        return Promise.reject()
      }
      return res.body
    }
  })
}
