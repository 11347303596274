import { useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { usePostUsersByIdQuery, useUsersByIdQuery } from '../../hooks/userHooks'
import { getError } from '../../utils'

export default function UserEditPage() {
  const navigate = useNavigate()
  const params = useParams() // /user/:id
  const { id: userId } = params

  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const { mutateAsync: updateUser, isPending: loadingUpdate } = usePostUsersByIdQuery(userId!)
  const { data: userInfo, isLoading, error } = useUsersByIdQuery(userId!)

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email)
      setIsAdmin(userInfo.isAdmin)
    }
  }, [userInfo])

  const submitHandler = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    try {
      await updateUser({
        email: email,
        checkAdmin: isAdmin
      })

      alert('ユーザ編集成功')
      navigate('/admin/userlist')
    } catch (err) {
      alert(getError(err))
    }
  }

  return (
    <Container className="small-container">
      <Helmet>
        <title>Visnu EC - ユーザ編集 ${userId}</title>
      </Helmet>
      <h1 style={{ margin: 'auto', width: '50%' }}>ユーザ編集</h1>
      <h2 style={{ margin: 'auto', width: '50%' }}>Id: {userId}</h2>

      {isLoading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{getError(error)}</MessageBox>
      ) : (
        <Form style={{ margin: 'auto', width: '50%' }} onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="slug">
            <Form.Label>メール</Form.Label>
            <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} required />
          </Form.Group>
          <Form.Check
            className="mb-3"
            type="checkbox"
            id="isAdmin"
            label="isAdmin"
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
          />
          <div className="mb-3">
            <Button disabled={loadingUpdate} type="submit">
              更新
            </Button>
            {loadingUpdate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  )
}
