// @todo remove document.querySelector

import { ChangeEvent, useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSnackbar } from '../context/snackbarContext'
import { useNewShippingAddress, useShippingInfo, useUpdateShippingAddress } from '../hooks/shippingInfoHooks'
import { CommonDialog } from './dialog'
import { Divider, Stack, TextField, Typography, styled } from '@mui/material'
import { ShippingAddress } from '../types/ShippingInfo'
import { SectionTitle } from './typography'
import { InputTitle } from './form'
import { RectButton } from './button'
import AddBoxIcon from '@mui/icons-material/AddBox'

export const EditShippingInfoDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const { data: shippingInfo, isPending } = useShippingInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const { mutateAsync: updateShipping } = useUpdateShippingAddress()
  const { mutateAsync: newShipping } = useNewShippingAddress()

  const snackbar = useSnackbar()
  const [postalCodeError, setPostalCodeError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [nameError, setNameError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [, setFormIsValid] = useState(true)
  const [formIsNameValid, setFormIsNameValid] = useState('')
  const [formIsPostalCodeValid, setFormIsPostalCodeValid] = useState('')
  const [formIsAddressValid, setFormIsAddressValid] = useState('')
  const [formIsPhoneValid, setFormIsPhoneValid] = useState('')
  const [showAddAddress, setShowAddAddress] = useState(false)

  const [newFullName, setNewFullName] = useState('')
  const [newPostalCode, setNewPostalCode] = useState('')
  const [newAddress, setNewAddress] = useState('')
  const [, setNewPhone] = useState('')

  const handleNameValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let formIsValid = true
    setFormIsNameValid('')
    const fullNameInput = e.target.value
    if (e.target.id === 'new_name') {
      setNewFullName(fullNameInput)
    }
    //Name
    if (!fullNameInput) {
      formIsValid = false
      setNameError('*氏名は未入力です')
    } else if (fullNameInput.length > 30) {
      formIsValid = false
      setNameError('*氏名は30桁以上は入力できません')
    }

    //name 全角確認
    for (let i = 0; i < fullNameInput.length; i++) {
      const character = fullNameInput.charAt(i)
      if (!character.match(/^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/)) {
        formIsValid = false
        setNameError('*記号文字は入力できません')
      }
    }

    if (formIsValid) {
      setNameError('')
    }

    setFormIsValid(formIsValid)

    if (!formIsValid) {
      setFormIsNameValid(e.target.id)
    }

    if (shippingInfo?.shippingInfo) {
      shippingInfo?.shippingInfo.shippingAddress.map((data) => {
        if (data.addressId === e.target.id.replace('_name', '')) {
          data.fullName = fullNameInput
        }
      })
    }

    return formIsValid
  }

  const handlePostalCodeValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let formIsValid = true
    setFormIsPostalCodeValid('')
    const postalCodeInput = e.target.value
    if (e.target.id === 'new_postalCode') {
      setNewPostalCode(postalCodeInput)
    }
    //郵便番号
    if (!postalCodeInput) {
      formIsValid = false
      setPostalCodeError('*郵便番号は未入力です')
    } else if (postalCodeInput.length > 7) {
      formIsValid = false
      setPostalCodeError('*郵便番号は7桁以上は入力できません')
    }

    //郵便番号 半角確認
    for (let i = 0; i < postalCodeInput.length; i++) {
      const character = postalCodeInput.charAt(i)
      if (!character.match(/^[a-zA-Z0-9]+$/)) {
        formIsValid = false
        setPostalCodeError('*全角文字は入力できません')
      }
    }

    //郵便番号 数値確認
    const isnum = /^\d+$/.test(postalCodeInput)
    if (!isnum) {
      formIsValid = false
      setPostalCodeError('*数値のみ入力可能です')
    }

    const regex = /^[0-9]{7}$/
    if (!regex.test(postalCodeInput)) {
      formIsValid = false
      setPostalCodeError('*ハイフン無しの有効な郵便番号を入力してください')
    }

    if (formIsValid) {
      setPostalCodeError('')
    }

    setFormIsValid(formIsValid)
    if (!formIsValid) {
      setFormIsPostalCodeValid(e.target.id)
    }

    if (shippingInfo?.shippingInfo) {
      shippingInfo?.shippingInfo!.shippingAddress.map((data) => {
        if (data.addressId === e.target.id.replace('_postalCode', '')) {
          data.postalCode = postalCodeInput
        }
      })
    }

    return formIsValid
  }

  const handleAddressValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let formIsValid = true
    setFormIsAddressValid('')
    const addressInput = e.target.value
    if (e.target.id === 'new_address') {
      setNewAddress(addressInput)
    }
    //住所
    if (!addressInput) {
      formIsValid = false
      setAddressError('*住所は未入力です')
    } else if (addressInput.length > 50) {
      formIsValid = false
      setAddressError('*住所は50桁以上は入力できません')
    }

    if (formIsValid) {
      setAddressError('')
    }

    setFormIsValid(formIsValid)
    if (!formIsValid) {
      setFormIsAddressValid(e.target.id)
    }

    if (shippingInfo?.shippingInfo) {
      shippingInfo?.shippingInfo!.shippingAddress.map((data) => {
        if (data.addressId === e.target.id.replace('_address', '')) {
          data.address = addressInput
        }
      })
    }

    return formIsValid
  }

  const handlePhoneValidation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let formIsValid = true
    setFormIsPhoneValid('')
    const phoneInput = e.target.value
    if (e.target.id === 'new_phone') {
      setNewPhone(phoneInput)
    }

    //電話番号
    if (!phoneInput) {
      formIsValid = false
      setPhoneError('*電話番号は未入力です')
    } else if (phoneInput.length > 11) {
      formIsValid = false
      setPhoneError('*電話番号は11桁以上は入力できません')
    }

    //電話番号 半角確認
    for (let i = 0; i < phoneInput.length; i++) {
      const character = phoneInput.charAt(i)
      if (!character.match(/^[a-zA-Z0-9]+$/)) {
        formIsValid = false
        setPhoneError('*全角文字は入力できません')
      }
    }

    //電話番号 数値確認
    const isnum = /^\d+$/.test(phoneInput)
    if (!isnum) {
      formIsValid = false
      setPhoneError('*数値のみ入力可能です')
    }

    const regex =
      /^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/
    if (!regex.test(phoneInput)) {
      formIsValid = false
      setPhoneError('*有効な電話番号を入力してください')
    }

    if (formIsValid) {
      setPhoneError('')
    }

    setFormIsValid(formIsValid)

    if (!formIsValid) {
      setFormIsPhoneValid(e.target.id)
    }

    if (shippingInfo?.shippingInfo) {
      shippingInfo?.shippingInfo.shippingAddress.map((data) => {
        if (data.addressId === e.target.id.replace('_phone', '')) {
          data.phone = phoneInput
        }
      })
    }

    return formIsValid
  }

  const submitHandler = useCallback(async () => {
    if (document.getElementsByClassName('Mui-error').length > 0) {
      snackbar.showSnackbar('不正な入力があります', 'warning')
      return
    }

    const ShippingInfoUpdate = shippingInfo?.shippingInfo
    if (
      (document.getElementById('new_name') as HTMLInputElement).value == '' &&
      ((document.getElementById('new_postalCode') as HTMLInputElement).value ||
        (document.getElementById('new_address') as HTMLInputElement).value ||
        (document.getElementById('new_phone') as HTMLInputElement).value)
    ) {
      setFormIsNameValid('new_name')
      setNameError('*氏名は未入力です')
      snackbar.showSnackbar('不正な入力があります', 'warning')
      return
    } else if (
      (document.getElementById('new_postalCode') as HTMLInputElement).value == '' &&
      ((document.getElementById('new_name') as HTMLInputElement).value ||
        (document.getElementById('new_address') as HTMLInputElement).value ||
        (document.getElementById('new_phone') as HTMLInputElement).value)
    ) {
      setFormIsPostalCodeValid('new_postalCode')
      setPostalCodeError('*郵便番号は未入力です')
      snackbar.showSnackbar('不正な入力があります', 'warning')
      return
    } else if (
      (document.getElementById('new_address') as HTMLInputElement).value == '' &&
      ((document.getElementById('new_name') as HTMLInputElement).value ||
        (document.getElementById('new_postalCode') as HTMLInputElement).value ||
        (document.getElementById('new_phone') as HTMLInputElement).value)
    ) {
      setFormIsAddressValid('new_address')
      setAddressError('*住所は未入力です')
      snackbar.showSnackbar('不正な入力があります', 'warning')
      return
    } else if (
      (document.getElementById('new_phone') as HTMLInputElement).value == '' &&
      ((document.getElementById('new_name') as HTMLInputElement).value ||
        (document.getElementById('new_postalCode') as HTMLInputElement).value ||
        (document.getElementById('new_address') as HTMLInputElement).value)
    ) {
      setFormIsPhoneValid('new_phone')
      setPhoneError('*電話番号は未入力です')
      snackbar.showSnackbar('不正な入力があります', 'warning')
      return
    } else {
      let addressId = ''
      if (ShippingInfoUpdate) {
        if (
          (document.getElementById('new_name') as HTMLInputElement).value !== '' &&
          (document.getElementById('new_postalCode') as HTMLInputElement).value !== '' &&
          (document.getElementById('new_address') as HTMLInputElement).value !== '' &&
          (document.getElementById('new_phone') as HTMLInputElement).value !== ''
        ) {
          addressId = '' + (ShippingInfoUpdate.shippingAddress.length + 1)
          const newShippingAddress: ShippingAddress = {
            addressId: addressId,
            fullName: (document.getElementById('new_name') as HTMLInputElement).value,
            fullNameKana: '',
            postalCode: (document.getElementById('new_postalCode') as HTMLInputElement).value,
            address: (document.getElementById('new_address') as HTMLInputElement).value,
            phone: (document.getElementById('new_phone') as HTMLInputElement).value
          }
          ShippingInfoUpdate.shippingAddress.push(newShippingAddress)
        }

        updateShipping(ShippingInfoUpdate)
        setShowAddAddress(false)
        setFormIsNameValid('')
        setFormIsPostalCodeValid('')
        setFormIsAddressValid('')

        snackbar.showSnackbar('住所情報を更新しました', 'success')
      } else {
        addressId = '1'
        const newShippingAddress: ShippingAddress = {
          addressId: addressId,
          fullName: (document.getElementById('new_name') as HTMLInputElement).value,
          fullNameKana: '',
          postalCode: (document.getElementById('new_postalCode') as HTMLInputElement).value,
          address: (document.getElementById('new_address') as HTMLInputElement).value,
          phone: (document.getElementById('new_phone') as HTMLInputElement).value
        }

        await newShipping({ shippingAddress: newShippingAddress })
        setShowAddAddress(false)
        setFormIsNameValid('')
        setFormIsPostalCodeValid('')
        setFormIsAddressValid('')
        setFormIsPhoneValid('')

        snackbar.showSnackbar('住所情報を更新しました', 'success')
        window.location.reload()
      }
    }

    onClose()
  }, [onClose, snackbar])

  return (
    <CommonDialog
      onClose={onClose}
      open={open}
      title="Edit Shipping Address"
      subtitle="お届け先の編集"
      onSubmit={submitHandler}
      loading={isPending}
    >
      <SectionTitle>配送先の編集</SectionTitle>

      <Stack px="30px" gap={1} key={'shippingform'}>
        <InputTitle width={200}>登録済みのお届け先</InputTitle>

        {shippingInfo?.shippingInfo ? (
          shippingInfo?.shippingInfo!.shippingAddress.map((addressInfo) => (
            <>
              <Stack
                key={addressInfo.addressId}
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <Typography width={130} fontSize={16} fontWeight={500} sx={{ textAlign: 'right' }} color="black">
                    お届け先 {addressInfo.addressId}
                  </Typography>
                </Stack>
                <Stack height={40} justifyContent={'center'} sx={{ width: '100%' }}>
                  <Divider sx={{ bgcolor: 'black' }} />
                </Stack>
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px', marginBottom: '15px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>お届け先名称</InputTitle>
                </Stack>
                <Stack height={40} justifyContent={'center'}>
                  <StyledTextField
                    size="small"
                    sx={{ width: 190 }}
                    id={addressInfo.addressId + '_name'}
                    defaultValue={addressInfo.fullName}
                    onChange={(e) => handleNameValidation(e)}
                    inputProps={{ maxLength: 30 }}
                    error={formIsNameValid === addressInfo.addressId + '_name'}
                    helperText={formIsNameValid === addressInfo.addressId + '_name' && nameError ? nameError : ' '}
                  />
                </Stack>
              </Stack>

              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px', marginBottom: '15px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>電話番号</InputTitle>
                </Stack>
                <Stack height={40} justifyContent={'center'}>
                  <StyledTextField
                    size="small"
                    sx={{
                      width: 190,
                      '&  .MuiFormHelperText-root.Mui-error': {
                        marginRight: '-150px'
                      }
                    }}
                    id={addressInfo.addressId + '_phone'}
                    defaultValue={addressInfo.phone}
                    onChange={(e) => handlePhoneValidation(e)}
                    inputProps={{ maxLength: 11 }}
                    error={formIsPhoneValid === addressInfo.addressId + '_phone'}
                    helperText={formIsPhoneValid === addressInfo.addressId + '_phone' && phoneError ? phoneError : ' '}
                  />
                </Stack>
              </Stack>

              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px', marginBottom: '5px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>郵便番号</InputTitle>
                </Stack>
                <Stack height={40} justifyContent={'center'}>
                  <StyledTextField
                    size="small"
                    sx={{
                      width: 190,
                      '&  .MuiFormHelperText-root.Mui-error': {
                        marginRight: '-150px'
                      }
                    }}
                    id={addressInfo.addressId + '_postalCode'}
                    defaultValue={addressInfo.postalCode}
                    onChange={(e) => handlePostalCodeValidation(e)}
                    inputProps={{ maxLength: 7 }}
                    error={formIsPostalCodeValid === addressInfo.addressId + '_postalCode'}
                    helperText={
                      formIsPostalCodeValid === addressInfo.addressId + '_postalCode' && postalCodeError
                        ? postalCodeError
                        : ' '
                    }
                  />
                </Stack>
              </Stack>

              <Stack direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px' }}>
                <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>住所</InputTitle>
                <Stack>
                  <StyledTextField
                    size="small"
                    sx={{ width: '100%' }}
                    id={addressInfo.addressId + '_address'}
                    defaultValue={addressInfo.address}
                    onChange={(e) => handleAddressValidation(e)}
                    inputProps={{ maxLength: 100 }}
                    error={formIsAddressValid === addressInfo.addressId + '_address'}
                    helperText={
                      formIsAddressValid === addressInfo.addressId + '_address' && addressError
                        ? addressError
                        : '※マンション名や部屋番号等がある場合はすべて入力してください'
                    }
                  />
                </Stack>
              </Stack>
            </>
          ))
        ) : (
          <Typography fontSize={16} fontWeight={700}>
            未登録
          </Typography>
        )}

        <RectButton
          variant="contained"
          sx={{
            width: 200,
            background: '#236CB5',
            float: 'right',
            marginLeft: 'auto',
            marginRight: 0,
            marginBottom: '15px'
          }}
          startIcon={<AddBoxIcon />}
          onClick={() => {
            if (showAddAddress) {
              setShowAddAddress(false)
            } else {
              setShowAddAddress(true)
            }
          }}
        >
          お届け先住所を追加
        </RectButton>

        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap="50px"
            sx={{ marginLeft: '80px', marginBottom: '15px' }}
            display={showAddAddress ? 'flex' : 'none'}
          >
            <Stack height={40} justifyContent={'center'}>
              <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>お届け先名称</InputTitle>
            </Stack>
            <Stack height={40} justifyContent={'center'}>
              <StyledTextField
                size="small"
                sx={{ width: 190 }}
                id={'new_name'}
                value={newFullName}
                onChange={(e) => handleNameValidation(e)}
                inputProps={{ maxLength: 30 }}
                error={formIsNameValid === 'new_name'}
                helperText={formIsNameValid === 'new_name' && nameError ? nameError : ' '}
              />
            </Stack>
          </Stack>

          <Stack
            display={showAddAddress ? 'flex' : 'none'}
            direction={'row'}
            alignItems={'center'}
            gap="50px"
            sx={{ marginLeft: '80px', marginBottom: '15px' }}
          >
            <Stack height={40} justifyContent={'center'}>
              <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>電話番号</InputTitle>
            </Stack>
            <Stack height={40} justifyContent={'center'}>
              <StyledTextField
                size="small"
                sx={{
                  width: 190,
                  '&  .MuiFormHelperText-root.Mui-error': {
                    marginRight: '-150px'
                  }
                }}
                id={'new_phone'}
                onChange={(e) => handlePhoneValidation(e)}
                inputProps={{ maxLength: 11 }}
                error={formIsPhoneValid === 'new_phone'}
                helperText={formIsPhoneValid === 'new_phone' && phoneError ? phoneError : ' '}
              />
            </Stack>
          </Stack>

          <Stack
            display={showAddAddress ? 'flex' : 'none'}
            direction={'row'}
            alignItems={'center'}
            gap="50px"
            sx={{ marginLeft: '80px', marginBottom: '5px' }}
          >
            <Stack height={40} justifyContent={'center'}>
              <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>郵便番号</InputTitle>
            </Stack>
            <Stack height={40} justifyContent={'center'}>
              <StyledTextField
                size="small"
                sx={{
                  width: 190,
                  '&  .MuiFormHelperText-root.Mui-error': {
                    marginRight: '-150px'
                  }
                }}
                id={'new_postalCode'}
                value={newPostalCode}
                onChange={(e) => handlePostalCodeValidation(e)}
                inputProps={{ maxLength: 7 }}
                error={formIsPostalCodeValid === 'new_postalCode'}
                helperText={formIsPostalCodeValid === 'new_postalCode' && postalCodeError ? postalCodeError : ' '}
              />
            </Stack>
          </Stack>

          <Stack
            display={showAddAddress ? 'flex' : 'none'}
            direction={'row'}
            alignItems={'center'}
            gap="50px"
            sx={{ marginLeft: '80px' }}
          >
            <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>住所</InputTitle>
            <Stack>
              <StyledTextField
                size="small"
                sx={{ width: '100%' }}
                id={'new_address'}
                value={newAddress}
                onChange={(e) => handleAddressValidation(e)}
                inputProps={{ maxLength: 50 }}
                error={formIsAddressValid === 'new_address'}
                helperText={
                  formIsAddressValid === 'new_address' && addressError
                    ? addressError
                    : '※マンション名や部屋番号等がある場合はすべて入力してください'
                }
              />
            </Stack>
          </Stack>
        </>
      </Stack>
    </CommonDialog>
  )
}

const StyledTextField = styled(TextField)((a) => ({
  width: a.fullWidth ? '100%' : 350,
  '.MuiInputBase-root': {
    background: '#FFFFFF'
  }
}))
