import { useAuth0 } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { useGetAllOrdersQuery } from '../../hooks/orderHooks'
import { getError } from '../../utils'
import { apiClients } from '../../lib/apiClient'
import { getAPIAccessToken } from '../../lib/auth0'
import {
  Button,
  Container,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

export default function OrderListPage() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  const navigate = useNavigate()
  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const page = (() => {
    try {
      const p = sp.get('page')
      return p ? Number(p) : 1
    } catch (e) {
      return 1
    }
  })()

  const { data: responseOrders, isLoading, error } = useGetAllOrdersQuery(page)

  const deleteHandler = async (order: NonNullable<typeof responseOrders>['orders'][number]) => {
    if (window.confirm('注文は削除しますか?')) {
      try {
        const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

        await apiClients['/api/admin/orders/:id'].DELETE.client({
          headers: { Authorization: `Bearer ${token}` },
          params: { id: order._id }
        })
        alert('注文は削除できました')
        window.location.reload()
      } catch (err) {
        alert(getError(err))
      }
    }
  }

  return (
    <div>
      <Helmet>
        <title>Visnu EC - 注文管理</title>
      </Helmet>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <h1>注文管理</h1>
        <Stack gap={3}>
          {isLoading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{getError(error)}</MessageBox>
          ) : (
            <Stack gap={2}>
              <Typography>注文数： {responseOrders?.countOrders}</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Stripe ID</TableCell>
                    <TableCell>日付</TableCell>
                    <TableCell>合計</TableCell>
                    <TableCell>支払いステータス</TableCell>
                    <TableCell>配送ステータス</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responseOrders!.orders.map((order) => (
                    <TableRow key={order._id}>
                      <TableCell>{order._id}</TableCell>
                      <TableCell>{order.stripeId}</TableCell>
                      <TableCell>
                        {new Date(order.createdAt).toLocaleDateString('ja-JP', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                      <TableCell>¥{order.totalPrice}</TableCell>
                      <TableCell>
                        {order.isPaid && order.paidAt
                          ? new Date(order.paidAt).toLocaleDateString('ja-JP', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            })
                          : '未払い'}
                      </TableCell>

                      <TableCell>
                        {order.isDelivered && order.deliveredAt
                          ? new Date(order.deliveredAt).toLocaleDateString('ja-JP', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            })
                          : '未発送'}
                      </TableCell>
                      <TableCell>
                        <Button type="button" onClick={() => navigate(`/admin/order/${order._id}`)}>
                          詳細
                        </Button>
                        &nbsp;
                        <Button type="button" onClick={() => deleteHandler(order)}>
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Stack direction={'row'} gap={2} justifyContent={'center'}>
                <Pagination
                  page={page}
                  count={responseOrders?.pages}
                  renderItem={(item) => (
                    <PaginationItem component={Link} to={`/admin/orderlist?page=${item.page}`} {...item} />
                  )}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Container>
    </div>
  )
}
