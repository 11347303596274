import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useExecutePaymentIntent } from '../hooks/stripeHooks'
import { ApiError } from '../types/ApiError'
import { getError } from '../utils'
import { OrderConfirmationLoadingContext } from '../pages/protected/OrderConfirmationPage'

const CheckoutForm = (props: {
  checkoutFormRef: React.RefObject<HTMLFormElement>
  shippingAddress: {
    addressId: string
    fullName: string
    fullNameKana: string
    postalCode: string
    address: string
    phone: string
  }
  bcpConnectedOrgPlaceId?: string
}) => {
  const stripe = useStripe()

  const elements = useElements()
  const navigate = useNavigate()
  const { setLoading } = useContext(OrderConfirmationLoadingContext)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const executePaymentIntent = useExecutePaymentIntent()

  const handlePaymentSubmitted = async (paymentIntentId: string) => {
    try {
      await executePaymentIntent.mutateAsync({
        paymentIntentId,
        shippingAddress: props.shippingAddress,
        bcpConnectedOrgPlaceId: props.bcpConnectedOrgPlaceId
      })
      setLoading(false)
      navigate(`/order-complete`)
    } catch (err) {
      alert(getError(err as ApiError))
    }
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!stripe || !elements) {
      setLoading(false)
      return
    }

    await stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {},
        redirect: 'if_required'
      })
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message!)
          setLoading(false)
          return
        }
        handlePaymentSubmitted(result.paymentIntent.id)
      })
  }

  return (
    <form id="order-confirmation-payment-form" onSubmit={handleSubmit} ref={props.checkoutFormRef}>
      <PaymentElement />
      {errorMessage && <div className="cardError">*{errorMessage}</div>}
    </form>
  )
}

export default CheckoutForm
