import { zodResolver } from '@hookform/resolvers/zod'
import { useAuth0 } from '@auth0/auth0-react'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import {
  Avatar,
  Box,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { RectButton } from '../../components/button'
import { CommonDialog } from '../../components/dialog'
import { DottedDivider } from '../../components/divider'
import { InputTitle } from '../../components/form'
import { PageTitle, SectionTitle } from '../../components/typography'
import { useSnackbar } from '../../context/snackbarContext'
import { useGetOrderHistoryQuery } from '../../hooks/orderHooks'
import { useShippingInfo } from '../../hooks/shippingInfoHooks'
import { useUpdateUserInfo, useUserInfo } from '../../hooks/userInfoHooks'
import { formatDate, formatPrice, getImageUrl } from '../../utils'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Store } from '../../Store'
import { EditShippingInfoDialog } from '../../components/EditShippingInfoDialog'
import { useBcpOrganizations } from '../../hooks/bcpHooks'
import LinkIcon from '@mui/icons-material/Link'
import AddLinkIcon from '@mui/icons-material/AddLink'

const buttonWidth = 240

export default function MyPage() {
  const { isLoading, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  // state
  const [openEditUserInfoDialog, setOpenEditUserInfoDialog] = useState(false)
  const [openEditShippingInfoDialog, setOpenEditSShippingInfoDialog] = useState(false)
  const { dispatch } = useContext(Store)

  // hooks
  const { data: orders } = useGetOrderHistoryQuery(getAccessTokenSilently, getAccessTokenWithPopup)
  const shippingInfo = useShippingInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const { logout } = useAuth0()

  if (isLoading || !isAuthenticated) {
    return null
  }

  // callbacks
  const handleEditUserInfo = () => {
    setOpenEditUserInfoDialog(true)
  }
  const handleCloseEditUserInfoDialog = () => {
    setOpenEditUserInfoDialog(false)
  }

  //配送先住所編集用
  const handleEditShippingInfo = () => {
    setOpenEditSShippingInfoDialog(true)
  }
  const handleCloseEditShippingInfoDialog = () => {
    setOpenEditSShippingInfoDialog(false)
  }

  const handleLogOut = () => {
    dispatch({ type: 'USER_SIGNOUT' })
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Visnu EC - マイページ</title>
      </Helmet>
      <Container maxWidth="md" sx={{ position: 'relative', pt: '200px', pb: '130px' }}>
        <Stack gap={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle title="My Page" subtitle="マイページ" />
          <Typography fontSize={24} fontWeight={600}>
            お客様の情報
          </Typography>
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>アカウント情報</SectionTitle>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>会員ステータス</InputTitle>
              <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={2}>
                <Stack direction={'row'} alignItems={'center'} height={'100%'} gap="10px" flexGrow={1}>
                  <Avatar />
                  <Box>
                    {userInfo ? (
                      <>
                        <Typography fontSize={13} fontWeight={600}>
                          {userInfo.data?.name ?? userInfo.data?.email}
                        </Typography>
                        <Typography fontSize={11}>としてログイン中</Typography>
                      </>
                    ) : (
                      <Typography fontSize={11}>未ログイン</Typography>
                    )}
                  </Box>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap="10px">
                  <RectButton
                    size="small"
                    sx={{
                      background: '#FF7C7C',
                      width: 120,
                      height: 40,
                      color: 'white',
                      display: 'none'
                    }}
                  >
                    BCP連携
                  </RectButton>
                  <RectButton
                    size="small"
                    sx={{
                      background: '#3C3C3C',
                      width: 120,
                      height: 40,
                      color: 'white'
                    }}
                    onClick={handleLogOut}
                  >
                    ログアウト
                  </RectButton>
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems={'center'}>
              <DottedDivider />
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>氏名</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {userInfo.data?.name ?? '未設定'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>氏名カナ</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {userInfo.data?.nameKana ?? '未設定'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>電話番号</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {userInfo.data?.phone ?? '未設定'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>メールアドレス</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {userInfo.data?.email ?? '未設定'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px" display={'none'}>
              <InputTitle width={200}>既定のお支払い方法</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                クレジットカード
              </Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'center'}>
              <RectButton
                variant="contained"
                sx={{ width: buttonWidth }}
                startIcon={<ModeEditIcon />}
                onClick={handleEditUserInfo}
              >
                アカウント情報の編集
              </RectButton>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>お届け先</SectionTitle>

            {shippingInfo.data?.shippingInfo ? (
              <Stack direction={'row'} gap="50px" px="30px">
                <InputTitle width={200}>登録済みのお届け先</InputTitle>
                <Table>
                  <TableBody>
                    {shippingInfo.data?.shippingInfo.shippingAddress!.map((addressInfo) => (
                      <TableRow
                        sx={{
                          borderBottom: '1px solid rgba(224, 224, 224, 1)'
                        }}
                      >
                        <Stack gap={1}>
                          <Typography fontSize={16} fontWeight={700}>
                            {addressInfo === shippingInfo.data?.shippingInfo?.shippingAddress[0] ? (
                              <LocationOnOutlinedIcon style={{ color: '#FF7F00' }} />
                            ) : (
                              <LocationOnOutlinedIcon style={{ color: 'black' }} />
                            )}
                            {addressInfo.fullName}
                          </Typography>
                          <Stack pl={2}>
                            <Typography fontSize={12} fontWeight={700}>
                              〒{addressInfo.postalCode}
                            </Typography>
                            <Typography fontSize={12} fontWeight={700}>
                              {addressInfo.address}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            ) : (
              <div />
            )}
            <Stack direction={'row'} justifyContent={'center'}>
              <Stack>
                <RectButton
                  variant="contained"
                  sx={{ width: buttonWidth }}
                  startIcon={<ModeEditIcon />}
                  onClick={handleEditShippingInfo}
                >
                  お届け先の編集
                </RectButton>
              </Stack>
            </Stack>
          </Stack>
          <VisnuBcpSection />
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>注文履歴</SectionTitle>
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>注文ID</TableCell>
                    <TableCell>発注日</TableCell>
                    <TableCell>決済金額</TableCell>
                    <TableCell>支払状況</TableCell>
                    <TableCell>ステータス</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!orders?.orders.length ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography fontSize={16} fontWeight={600}>
                          注文がありません
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    orders?.orders.map((order) => (
                      <TableRow>
                        <TableCell>
                          <Stack direction={'row'} gap={2}>
                            <Box
                              component={'img'}
                              src={getImageUrl(order.orderItems.image)}
                              sx={{
                                width: 40,
                                height: 50,
                                borderRadius: '5px'
                              }}
                            />
                            <Stack justifyContent={'center'}>
                              <Typography fontSize={16} fontWeight={600}>
                                {order._id}
                              </Typography>
                              <Typography fontSize={12} fontWeight={300}>
                                {order.orderItems.quantity}商品ご注文
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell>{formatDate(new Date(order.createdAt))}</TableCell>
                        <TableCell>{formatPrice(order.totalPrice)}</TableCell>
                        <TableCell>{order.isPaid ? '決済済み' : '未払い'}</TableCell>
                        <TableCell>{!order.isDelivered ? '発送待ち' : '発送済み'}</TableCell>
                        <TableCell>
                          <RectButton component={Link} to={`/order/${order._id}`} variant="contained">
                            注文詳細
                          </RectButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              {/* TODO ページング */}
              {/* <Stack alignItems={'center'} py="60px" gap={4.5}>
                <DottedDivider />
                <Stack direction={'row'} gap={3} alignItems={'center'}>
                  {[...Array(['']).keys()].map((x, i) => (
                    <Link
                      key={i}
                      to={{
                        pathname: '/order-history',
                      }}
                    >
                      <ButtonBase
                        sx={{
                          color: '#3C3C3C',
                          fontSize: 1 === x + 1 ? 24 : 16,
                          fontWeight: 600,
                          borderBottom:
                            1 === x + 1 ? '4px solid #707070' : 'none',
                        }}
                      >
                        {x + 1}
                      </ButtonBase>
                    </Link>
                  ))}
                </Stack>
              </Stack> */}
            </>
          </Stack>
        </Stack>
      </Container>
      <EditUserInfoDialog
        open={openEditUserInfoDialog}
        onClose={() => {
          handleCloseEditUserInfoDialog()
        }}
      />
      <EditShippingInfoDialog
        open={openEditShippingInfoDialog}
        onClose={() => {
          handleCloseEditShippingInfoDialog()
        }}
      />
    </>
  )
}

const editUserInfoFormSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: '入力してください'
    })
    .max(100, {
      message: '100文字以内で入力してください'
    }),
  nameKana: z
    .string()
    .min(1, { message: '入力してください' })
    .max(100, {
      message: '100文字以内で入力してください'
    })
    .regex(/^[ァ-ヶー \u3000]*$/, {
      message: '全角カタカナで入力してください'
    }),
  phone: z
    .string()
    .regex(/^[0-9]*$/, {
      message: '数字のみで入力してください'
    })
    .min(10, {
      message: '10桁以上の数字を入力してください'
    })
    .max(11, {
      message: '11桁以下の数字を入力してください'
    }),
  email: z.string().email({
    message: '正しいメールアドレスを入力してください'
  })
})

type EditUserInfoFormSchemaType = z.infer<typeof editUserInfoFormSchema>

const EditUserInfoDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  // hooks
  const form = useForm<EditUserInfoFormSchemaType>({
    resolver: zodResolver(editUserInfoFormSchema)
  })
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const updateUserInfo = useUpdateUserInfo()
  const snackbar = useSnackbar()

  // callbacks
  const handleSubmit = useCallback(() => {
    form.handleSubmit(async (values) => {
      await updateUserInfo.mutateAsync(values)
      snackbar.showSnackbar('アカウント情報を更新しました', 'success')
      onClose()
    })()
  }, [form, onClose, snackbar, updateUserInfo])

  // effects
  useEffect(() => {
    // ユーザー情報が取得できたらフォームにセット
    if (userInfo.data) {
      form.reset(userInfo.data)
    }
  }, [form, userInfo.data])
  useEffect(() => {
    // ダイアログが閉じたらフォームをリセット
    if (!open) {
      form.reset()
    }
  }, [form, open])

  return (
    <CommonDialog
      onClose={onClose}
      open={open}
      title="Edit Account Information"
      subtitle="アカウント情報の編集"
      onSubmit={handleSubmit}
      loading={userInfo.isPending || updateUserInfo.isPending}
    >
      <SectionTitle>アカウント情報の編集</SectionTitle>
      <Stack px="30px" gap={1}>
        <Stack direction={'row'} gap="50px">
          <Stack height={40} justifyContent={'center'}>
            <InputTitle>氏名</InputTitle>
          </Stack>
          <StyledTextField
            {...form.register('name')}
            size="small"
            error={Boolean(form.formState.errors.name?.message)}
            helperText={form.formState.errors.name?.message ?? ' '}
          />
        </Stack>
        <Stack direction={'row'} gap="50px">
          <Stack height={40} justifyContent={'center'}>
            <InputTitle>氏名カナ</InputTitle>
          </Stack>
          <StyledTextField
            {...form.register('nameKana')}
            size="small"
            error={Boolean(form.formState.errors.nameKana?.message)}
            helperText={form.formState.errors.nameKana?.message ?? ' '}
          />
        </Stack>
        <Stack direction={'row'} gap="50px">
          <Stack height={40} justifyContent={'center'}>
            <InputTitle>電話番号</InputTitle>
          </Stack>
          <StyledTextField
            {...form.register('phone')}
            size="small"
            error={Boolean(form.formState.errors.phone?.message)}
            helperText={form.formState.errors.phone?.message ?? '※ ハイフンなしでご入力ください'}
          />
        </Stack>
      </Stack>
    </CommonDialog>
  )
}

const StyledTextField = styled(TextField)((a) => ({
  width: a.fullWidth ? '100%' : 350,
  '.MuiInputBase-root': {
    background: '#FFFFFF'
  }
}))

const VisnuBcpSection = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  // states
  const [openEditBcpConnectedOrgDialog, setOpenEditBcpConnectedOrgDialog] = useState(false)

  // hooks
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)

  // memos
  const isConnected = useMemo(() => {
    return userInfo.data?.bcpConnectedOrg?._id ? true : false
  }, [userInfo.data])
  const status = useMemo(() => {
    if (!userInfo.data?.bcpConnectedOrg?._id) {
      return '未連携'
    }
    if (userInfo.data.bcpConnectedOrg.error) {
      switch (userInfo.data.bcpConnectedOrg.error) {
        case 'notMember':
          return 'すでに所属していない組織が連携されています'
        case 'orgNotFound':
          return '連携された組織が見つかりません'
        default:
          return '不明なエラー'
      }
    }
    return '連携済み'
  }, [userInfo.data])
  const orgName = useMemo(() => {
    return userInfo.data?.bcpConnectedOrg?.name ?? (userInfo.data?.bcpConnectedOrg?.error ? '-' : '未連携')
  }, [userInfo.data])

  return (
    <>
      <Stack sx={{ width: '100%' }} gap={3}>
        <SectionTitle>VisnuBCP連携</SectionTitle>
        <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
          <InputTitle width={200}>連携ステータス</InputTitle>
          <Typography fontSize={16} fontWeight={600}>
            {status}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
          <InputTitle width={200}>連携組織名</InputTitle>
          <Typography fontSize={16} fontWeight={600}>
            {orgName}
          </Typography>
        </Stack>
        {!isConnected ? (
          <Stack alignItems={'center'} gap="50px" px="30px">
            <Typography fontSize={14}>
              VisnuBCPとアカウント連携をすることで、商品購入時にVisnuBCPへの在庫の自動登録が可能となります。
            </Typography>
          </Stack>
        ) : null}
        <Stack direction={'row'} justifyContent={'center'}>
          <RectButton
            variant="contained"
            sx={{ width: buttonWidth }}
            startIcon={isConnected ? <LinkIcon /> : <AddLinkIcon />}
            onClick={() => {
              setOpenEditBcpConnectedOrgDialog(true)
            }}
          >
            アカウント連携{isConnected ? '変更・解除' : null}
          </RectButton>
        </Stack>
      </Stack>
      <EditBcpConnectedOrgDialog
        open={openEditBcpConnectedOrgDialog}
        onClose={() => {
          setOpenEditBcpConnectedOrgDialog(false)
        }}
      />
    </>
  )
}

const EditBcpConnectedOrgDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const formSchema = z.object({
    bcpOrganizationId: z.string()
  })

  // hooks
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bcpOrganizationId: ''
    }
  })
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const updateUserInfo = useUpdateUserInfo()
  const snackbar = useSnackbar()
  const bcpOrganizations = useBcpOrganizations()

  // callbacks
  const handleSubmit = useCallback(() => {
    form.handleSubmit(async (values) => {
      await updateUserInfo.mutateAsync({
        bcpConnectedOrgId: values.bcpOrganizationId
      })
      snackbar.showSnackbar('BCPアカウント連携を更新しました', 'success')
      onClose()
    })()
  }, [form, onClose, snackbar, updateUserInfo])

  // effects
  useEffect(() => {
    // ユーザー情報が取得できたらフォームにセット
    if (userInfo.data) {
      form.reset({
        bcpOrganizationId: userInfo.data.bcpConnectedOrg?._id ?? ''
      })
    }
  }, [form, userInfo.data])
  useEffect(() => {
    // ダイアログが閉じたらフォームをリセット
    if (!open) {
      form.reset({
        bcpOrganizationId: userInfo.data?.bcpConnectedOrg?._id ?? ''
      })
    }
  }, [form, open, userInfo.data?.bcpConnectedOrg?._id])

  return (
    <CommonDialog
      onClose={onClose}
      open={open}
      title="Edit BCP Connected Organization"
      subtitle="BCPアカウント連携の編集"
      onSubmit={handleSubmit}
      loading={userInfo.isPending || updateUserInfo.isPending}
    >
      <SectionTitle>BCPアカウント連携組織の編集</SectionTitle>
      <Stack px="30px" gap={1}>
        <Stack direction={'row'} gap="50px">
          <Stack height={40} justifyContent={'center'}>
            <InputTitle>連携組織</InputTitle>
          </Stack>
          <Controller
            control={form.control}
            name="bcpOrganizationId"
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  displayEmpty
                  size="small"
                  sx={{
                    background: '#FFFFFF'
                  }}
                >
                  <MenuItem value="">連携しない</MenuItem>
                  {bcpOrganizations.data?.organizations.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>
      </Stack>
    </CommonDialog>
  )
}
