import { Box, Button, ButtonBase, ButtonProps, CircularProgress, Stack, Typography, styled } from '@mui/material'
import { motion } from 'framer-motion'
import { ElementType, useCallback, useMemo } from 'react'

export const RoundedButton = <T extends ElementType>({
  color = 'primaryGradient',
  hoverColor,
  size = 'medium',
  width,
  loading,
  ...props
}: {
  color?: 'primaryGradient' | 'secondaryGradient' | 'blue' | 'black' | 'gray' | 'darkGray' | 'orange'
  hoverColor?: string
  size?: 'medium' | 'small' | 'tiny'
  width?: number
  loading?: boolean
} & Omit<ButtonProps<T, { component?: T }>, 'color' | 'size'>) => {
  const colors = useMemo<{ fontColor: string; background: string }>(() => {
    switch (color) {
      case 'primaryGradient':
        return {
          fontColor: '#FFFFFF',
          background: 'linear-gradient(90deg, #FF7F00 0%, #FF7C7C 50%, #FF41DB 100%)'
        }
      case 'secondaryGradient':
        return {
          fontColor: '#3C3C3C',
          background: 'linear-gradient(90deg, #F0FFF5 0%, #F5E8FF 50%, #FDEDED 100%)'
        }
      case 'blue':
        return {
          fontColor: '#FFFFFF',
          background: '#1976d2'
        }
      case 'black':
        return { fontColor: '#FFFFFF', background: '#3C3C3C' }
      case 'gray':
        return { fontColor: '#3C3C3C', background: '#F2F2F2' }
      case 'darkGray':
        return { fontColor: '#FFFFFF', background: '#868686' }
      case 'orange':
        return { fontColor: '#FFFFFF', background: '#FF7F00' }
      default:
        return { fontColor: '#FFFFFF', background: '#FF5252' }
    }
  }, [color])
  const disabledColors = useMemo<{ fontColor: string; background: string }>(
    () => ({
      fontColor: '#FFFFFF',
      background: '#AAAAAA'
    }),
    []
  )
  const sizes = useMemo<{ height: number; fontSize: number }>(() => {
    switch (size) {
      case 'medium':
        return { height: 40, fontSize: 13 }
      case 'small':
        return { height: 30, fontSize: 12 }
      case 'tiny':
        return { height: 20, fontSize: 11 }
    }
  }, [size])

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (loading) return
      props.onClick?.(e)
    },
    [loading, props]
  )

  return (
    <ButtonBase
      {...props}
      onClick={handleClick}
      variant="contained"
      sx={{
        ...props.sx,
        width: width ?? 180,
        height: sizes.height,
        background: 'none',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box component={motion.div} initial="rest" whileHover={props.disabled ? 'rest' : 'hover'} animate="rest">
        <Box
          component={motion.div}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: props.disabled ? disabledColors.background : colors.background,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1
          }}
          variants={{
            rest: {
              opacity: 1
            },
            hover: {
              opacity: 0,
              transition: {
                delay: 0.2
              }
            }
          }}
        />
        <Box
          component={motion.div}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: hoverColor ?? '#3C3C3C',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0
          }}
          variants={{
            rest: {
              opacity: 0,
              transition: {
                delay: 0.2
              }
            },
            hover: {
              opacity: 1
            }
          }}
        />
        {loading ? (
          <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress
              size={20}
              sx={{
                color: props.disabled ? disabledColors.fontColor : colors.fontColor
              }}
            />
          </Box>
        ) : (
          <Typography
            component={motion.p}
            fontSize={sizes.fontSize}
            fontWeight={600}
            lineHeight={1}
            position={'relative'}
            zIndex={1}
            variants={{
              rest: {
                color: props.disabled ? disabledColors.fontColor : colors.fontColor,
                transition: {
                  delay: 0.2
                }
              },
              hover: {
                color: '#FFFFFF'
              }
            }}
            initial={{
              opacity: 0,
              color: props.disabled ? disabledColors.fontColor : colors.fontColor
            }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {props.children}
          </Typography>
        )}
      </Box>
    </ButtonBase>
  )
}

export const RectButton = styled(Button)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
  ':hover': {
    boxShadow: 'none',
    background: '#3C3C3C',
    color: '#FFFFFF',
    '*': {
      color: '#FFFFFF'
    }
  }
})) as typeof Button

export const CategoryButton = (props: {
  title: string
  subtitle: string
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}) => {
  return (
    <RectButton
      variant="contained"
      size="small"
      sx={{
        width: 140,
        background: props.active ? 'primary' : '#868686'
      }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Stack gap={'2px'}>
        <Typography fontSize={11} lineHeight={1} color="#FFFFFF">
          {props.subtitle}
        </Typography>
        <Typography fontSize={13} fontWeight={600} lineHeight={1} color="#FFFFFF">
          {props.title}
        </Typography>
      </Stack>
    </RectButton>
  )
}
