import { Box, Button, Container, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { RoundedButton } from '../../components/button'
import { ProductCategoryChip } from '../../components/common'
import { InputTitle } from '../../components/form'
import { PageTitle, SectionTitle } from '../../components/typography'
import { useGetOrderDetailsQuery } from '../../hooks/orderHooks'
import { formatDate, formatPrice, getError, getImageUrl } from '../../utils'

export default function OrderPage() {
  const params = useParams()
  const { id: orderId } = params
  const { data: order, isLoading, error } = useGetOrderDetailsQuery(orderId!)

  return isLoading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{getError(error)}</MessageBox>
  ) : !order ? (
    <MessageBox variant="danger">注文見つかりません</MessageBox>
  ) : (
    <>
      <Helmet>
        <title>Visnu EC - 注文</title>
      </Helmet>
      <Container maxWidth="md" sx={{ position: 'relative', pt: '200px', pb: '130px' }}>
        <Stack gap={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle title="Order Details" subtitle="注文詳細" />
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>基本情報</SectionTitle>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>発注日</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {formatDate(new Date(order.createdAt))}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>注文ID</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {order._id}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>ご注文商品</SectionTitle>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <Table>
                <TableBody>
                  {order.orderItems.map((item) => (
                    <TableRow>
                      <TableCell>
                        <Box
                          component="img"
                          src={getImageUrl(item.image)}
                          alt={item.name}
                          width={80}
                          height={100}
                          sx={{ borderRadius: '10px' }}
                        />
                      </TableCell>
                      <TableCell>
                        <Stack gap={1}>
                          <ProductCategoryChip>食品</ProductCategoryChip>
                          {item.name}
                        </Stack>
                      </TableCell>
                      <TableCell>{formatPrice(item.price)}</TableCell>
                      <TableCell>数量：{item.quantity}</TableCell>
                      <TableCell>{formatPrice(item.price * item.quantity)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>ご注文情報</SectionTitle>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>お届け先</InputTitle>
              <Stack gap={0.5}>
                <Typography fontSize={16} fontWeight={600}>
                  {order.shippingAddress?.fullName}
                </Typography>
                <Stack pl={1}>
                  <Typography fontSize={12} fontWeight={600}>
                    {order.shippingAddress?.postalCode}
                  </Typography>
                  <Typography fontSize={12} fontWeight={600}>
                    {order.shippingAddress?.address}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>お支払い方法</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {order.paymentMethod}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>配送情報</SectionTitle>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>配送状況</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {order.isDelivered ? '配送済み' : '未配送'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>配送業者</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {order.shippingCompany ?? '未登録'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>伝票番号</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                {order.trackingNumber ?? '未登録'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>配送状況</InputTitle>
              {/* TODO */}
              {order.isDelivered && order.shippingCompany === 'ヤマト' ? (
                <Button
                  component={Link}
                  to={'https://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=' + order.trackingNumber}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  配送状況を確認する（外部サイト）
                </Button>
              ) : order.isDelivered && order.shippingCompany === '佐川' ? (
                <Button
                  component={Link}
                  to={
                    'https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S003&locale=ja&SVID=023&reqCodeNo1=' +
                    order.trackingNumber
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  配送状況を確認する（外部サイト）
                </Button>
              ) : order.isDelivered && order.shippingCompany === '西濃' ? (
                <Button
                  component={Link}
                  to={'https://track.seino.co.jp/cgi-bin/gnpquery.pgm?GNPNO1=' + order.trackingNumber}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  配送状況を確認する（外部サイト）
                </Button>
              ) : order.isDelivered && order.shippingCompany === 'その他' ? (
                <Typography>配送状況はお問い合わせでお願いします。</Typography>
              ) : (
                <Typography fontSize={16} fontWeight={600}>
                  未発送
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'center'}>
            <RoundedButton component={Link} to="/mypage" color="gray">
              MyPageへ戻る
            </RoundedButton>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}
