import { Alert, LinearProgress, Stack, Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { usePaymentIntent } from '../hooks/stripeHooks'
import CheckoutForm from './CheckoutForm'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

const initStripe = async (token: string | undefined) => {
  if (!token) {
    throw new Error('token not found')
  }
  const res = await apiClients['/api/stripe/publishable-key'].GET.client({
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res.ok) {
    throw new Error('stripe publishable key not found')
  }
  const publishableKey = await res.body.publishable_key

  return loadStripe(publishableKey)
}

const Checkout = (props: {
  checkoutFormRef: React.RefObject<HTMLFormElement>
  shippingAddress: Parameters<typeof CheckoutForm>[0]['shippingAddress']
  bcpConnectedOrgPlaceId?: string
}) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const paymentIntent = usePaymentIntent()

  useEffect(() => {
    if (paymentIntent.isSuccess || paymentIntent.isError || paymentIntent.isPending) return
    paymentIntent.mutate()
  }, [paymentIntent])

  return (
    <div>
      {paymentIntent.isIdle || paymentIntent.isPending ? (
        <Stack gap={1}>
          <LinearProgress />
          <Typography>決済情報を取得しています...</Typography>
        </Stack>
      ) : paymentIntent.isSuccess && paymentIntent.data.clientSecret ? (
        <Elements
          stripe={(async () => {
            const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
            return initStripe(token)
          })()}
          options={{
            clientSecret: paymentIntent.data.clientSecret,
            appearance: { theme: 'stripe' },
            locale: 'ja'
          }}
        >
          <CheckoutForm
            checkoutFormRef={props.checkoutFormRef}
            shippingAddress={props.shippingAddress}
            bcpConnectedOrgPlaceId={props.bcpConnectedOrgPlaceId}
          />
        </Elements>
      ) : (
        <Alert color="error">決済情報の取得に失敗しました。</Alert>
      )}
    </div>
  )
}

export default Checkout
