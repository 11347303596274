import type { AuthorizationParams } from '@auth0/auth0-react'
const devFlag = process.env.NODE_ENV !== 'production'

export const ENV = {
  dev: devFlag
} as const

export const HOST = {
  API: devFlag ? 'http://localhost:4000' : 'https://ec-api.visnu.io'
} as const

export const auth0 = {
  domain: 'visnu.jp.auth0.com',
  // dev環境はvisnu-cloudと共有
  clientId: devFlag ? 'uS46uosoAzkWyVNCoMYaBbxwFJBIblS5' : 'WZKLz8iyF3SaBeQS9ZiYQDtC6IjNkufC',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: 'https://visnu.jp.auth0.com/api/v2/'
  } satisfies AuthorizationParams
} as const
