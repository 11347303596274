import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import CartIcon from '@mui/icons-material/ShoppingCart'
import { Box, ButtonBase, Container, Divider, Stack, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Store } from '../Store'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import { RectButton, RoundedButton } from '../components/button'
import { ProductCategoryChip } from '../components/common'
import { useSnackbar } from '../context/snackbarContext'
import { useAddCartMutation } from '../hooks/cartHooks'
import { useGetProductDetailsBySlugQuery } from '../hooks/productHooks'
import { convertProductToCartItem, formatPrice, getError, getImageUrl } from '../utils'

/**
 * 商品ページ
 *
 */
export default function ProductPage() {
  //states
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)

  const params = useParams()
  const { slug } = params
  const { data: product, isLoading, error } = useGetProductDetailsBySlugQuery(slug!)
  const snackbar = useSnackbar()

  //サイトの状態を取得して、カート状態を一緒に取得
  const { state, dispatch } = useContext(Store)
  const { userInfo } = state

  const navigate = useNavigate()
  const { mutateAsync: addCart, isPending } = useAddCartMutation()
  //商品をカートに追加
  const handleAddToCart = async () => {
    let quantity = 1
    //DBに保存
    if (!userInfo) {
      const existItem = state.cart.cartItems.find((x) => x.id === product!._id)

      //存在した場合は数量を1個足す
      quantity = existItem ? existItem.quantity + 1 : 1

      dispatch({
        type: 'CART_ADD_ITEM',
        payload: { ...convertProductToCartItem(product!), quantity }
      })

      snackbar.showSnackbar(`カートに追加しました : ${product?.name}`, 'success')

      navigate('/cart')

      return
    }
    try {
      await addCart({
        cartItem: {
          id: convertProductToCartItem(product!).id,
          quantity: quantity
        }
      })
      snackbar.showSnackbar(`カートに追加しました : ${product?.name}`, 'success')
    } catch (err) {
      snackbar.showSnackbar(getError(err), 'error')
    }

    //カートページに移動
    navigate('/cart')
  }

  // callbacks
  const handleChangeImage = useCallback((image: string) => {
    const img = getImageUrl(image)
    if (!img) return
    setSelectedImageUrl(img)
  }, [])

  // effects
  useEffect(() => {
    if (product) {
      const img = getImageUrl(product.images[0])
      if (!img) return
      setSelectedImageUrl(img)
    }
  }, [product])

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{getError(error)}</MessageBox>
      ) : !product ? (
        <MessageBox variant="danger">商品見つかりません</MessageBox>
      ) : (
        <Container maxWidth="md" sx={{ pt: '160px', pb: '200px' }}>
          <Stack gap={4}>
            <Stack direction={'row'} gap={'30px'}>
              <Stack width={450} gap={4}>
                <Box
                  component={'img'}
                  src={selectedImageUrl ?? ''}
                  alt={product.name}
                  sx={{
                    height: 335,
                    objectFit: 'cover',
                    width: '100%',
                    background: 'white',
                    borderRadius: '15px'
                  }}
                />
                <Divider sx={{ borderBottomColor: '#707070' }} />
                <Stack direction={'row'} gap={2}>
                  {product.images.map((image, index) => (
                    <ButtonBase key={index} onClick={() => handleChangeImage(image)}>
                      <Box
                        component={'img'}
                        src={getImageUrl(image)}
                        alt={product.name}
                        loading="lazy"
                        sx={{
                          height: 100,
                          width: 100,
                          objectFit: 'cover',
                          background: 'white',
                          borderRadius: '5px'
                        }}
                      />
                    </ButtonBase>
                  ))}
                </Stack>
              </Stack>
              <Stack gap={4} flexGrow={1}>
                <Stack height={335} gap={2}>
                  <ProductCategoryChip>{product.category}</ProductCategoryChip>
                  <Typography fontSize={36} fontWeight={500}>
                    {product.name}
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Typography fontSize={24} fontWeight={500}>
                      {formatPrice(product.prices.oneTime.unitAmount)}
                    </Typography>
                  </Box>
                </Stack>
                <RectButton variant="contained" disabled={isPending} startIcon={<CartIcon />} onClick={handleAddToCart}>
                  カートに追加
                </RectButton>
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Divider sx={{ borderBottomColor: '#707070' }} />
              <Box sx={{ background: '#FDF1E0', borderRadius: '5px' }}>
                <Typography fontSize={24} fontWeight={500} p={1}>
                  商品説明
                </Typography>
              </Box>
              <Typography fontSize={16} py={2} pb={8}>
                <div dangerouslySetInnerHTML={{ __html: product.description }} />
              </Typography>
              <Box alignSelf={'center'}>
                <Box
                  component={Link}
                  to="/"
                  sx={{
                    textDecoration: 'none'
                  }}
                >
                  <RoundedButton
                    component={Link}
                    to="/"
                    color="gray"
                    startIcon={
                      <ExpandCircleDownIcon
                        sx={{
                          transform: 'rotate(90deg)',
                          color: '#B1B1B1',
                          fontSize: '16px !important'
                        }}
                      />
                    }
                  >
                    商品一覧へ戻る
                  </RoundedButton>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Container>
      )}
    </>
  )
}
