import { useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { getError } from '../../utils'
import { useGetCategoryByIdQuery, usePostCategoryByIdQuery } from '../../hooks/categoriesHooks'

export default function CategoryEditPage() {
  const navigate = useNavigate()
  const params = useParams() // /category/:id
  const { id: categoryId } = params

  const [label, setLabel] = useState('')
  const [jpLabel, setJpLabel] = useState('')

  const { data: CategoryInfo, isLoading, error } = useGetCategoryByIdQuery(categoryId!)

  useEffect(() => {
    if (CategoryInfo) {
      setLabel(CategoryInfo.label)
      setJpLabel(CategoryInfo.jpLabel)
    }
  }, [CategoryInfo])

  const { mutateAsync: updateCategory, isPending: loadingUpdate } = usePostCategoryByIdQuery(categoryId!)

  const submitHandler = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    try {
      await updateCategory({
        label: label,
        jpLabel: jpLabel
      })

      alert('カテゴリ編集成功')
      navigate('/admin/categorylist')
    } catch (err) {
      alert(getError(err))
    }
  }

  return (
    <Container className="small-container">
      <Helmet>
        <title>Visnu EC - カテゴリ編集 ${categoryId}</title>
      </Helmet>
      <h1 style={{ margin: 'auto', width: '50%' }}>カテゴリ編集</h1>
      <h2 style={{ margin: 'auto', width: '50%' }}>Id: {categoryId}</h2>

      {isLoading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{getError(error)}</MessageBox>
      ) : (
        <Form style={{ margin: 'auto', width: '50%' }} onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="label">
            <Form.Label>ラベル</Form.Label>
            <Form.Control value={label} onChange={(e) => setLabel(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="jpLabel">
            <Form.Label>ラベル日本語名</Form.Label>
            <Form.Control value={jpLabel} onChange={(e) => setJpLabel(e.target.value)} required />
          </Form.Group>
          <div className="mb-3">
            <Button disabled={loadingUpdate} type="submit">
              更新
            </Button>
            {loadingUpdate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  )
}
